import React from 'react';
import { Routes as ReactRoutes, Route, Navigate } from 'react-router-dom';

import Home from '../pages/Home';

const Routes = () => (
  <ReactRoutes>
    <Route path='/' element={<Home />} />
    <Route path='*' element={<Navigate to="/" />} />
  </ReactRoutes>
);

export default Routes;