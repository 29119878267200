
import MuiTextField from '@mui/material/TextField';

import { Container } from './styles';

const TextField = ({ error, ...rest }) => {
  return (
    <Container>
      <MuiTextField
        error={!!error}
        variant="outlined"
        margin="normal"
        helperText={error}
        fullWidth
        sx={{
          "& label": {
            color: "white"
          },
          "&": {
            "& fieldset": {
              borderColor: "white"
            }
          },
          "& input": {
            color: "white"
          },
          "& label.Mui-focused": {
            color: "white"
          },
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: "white"
            }
          },
          "&:hover": {
            "&:hover fieldset": {
              borderColor: "white"
            }
          }
        }}
        {...rest}
      />
    </Container>
  );
}

export default TextField;