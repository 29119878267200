
import MuiButton from '@mui/material/Button';

import { Container } from './styles';

const Button = ({...rest}) => {
  return (
    <Container>
      <MuiButton
        variant="contained"
        {...rest}
      >
        Enviar
      </MuiButton>
    </Container>
  );
};

export default Button;