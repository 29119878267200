import { useState, useEffect } from 'react';

import TextField from '../TextField';
import Button from '../Button';
import { Container } from './styles';

const Step = ({ text, onlyText, handleClick, error, clearError, currentStep }) => {
  const [value, setValue] = useState('');

  const handleChange = (event) => {
    clearError();
    setValue(event.target.value);
  }

  useEffect(() => {
    if (!!error) {
      setValue('');
    }
  }, [error]);

  useEffect(() => {
    setValue('');
  }, [currentStep]);

  return (
    <Container>
      <h1>{ text }</h1>
      {!onlyText && (
        <>
          <TextField onChange={handleChange} error={error} value={value}/>
          <Button onClick={() => handleClick(value)} />
        </>
      )}
    </Container>
  );
};

export default Step;