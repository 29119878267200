import React from 'react';

import Step from '../../components/Step';
import { Container } from './styles';

const Home = () => {
  const [currentStep, setCurrentStep] = React.useState(0);
  const [error, setError] = React.useState(null);

  const currentStepData = React.useMemo(() => {
    const steps = [
      {
        text: 'Talvez você devesse dar uma olhada no armário de produtos de limpeza',
        tip: 'nossa data (DD/MM/AAAA)',
        correctAnswer: '26/06/2022'
      },
      {
        text: 'Que tal você dar uma olhada nas minhas meias (as limpas kkkkk)',
        tip: 'tema do primeiro presente que eu dei',
        correctAnswer: 'Harry Potter'
      },
      {
        text: 'O último item está comigo, você só precisa me dar um beijo pra receber ele',
        tip: 'série que eu só assisto com você',
        correctAnswer: 'Friends'
      },
      {
        text: 'É isso, só queria dizer que eu te amo demais, mais que tudo, e que você é o amor da minha vida, espero que tenha gostado dos presentinhos :)',
        correctAnswer: null
      }
    ];

    return steps[currentStep];
  }, [currentStep]);

  const clearError = () => {
    setError(null);
  }

  const handleClick = (value) => {
    if (value.toLowerCase() === currentStepData.correctAnswer.toLowerCase()) {
      setCurrentStep((prevState) => (prevState + 1));
    } else {
      setError('ERRROUUU');
    }
  }

  return (
    <Container>
      <Step
        text={currentStepData.text}
        onlyText={!currentStepData.correctAnswer}
        currentStep={currentStep}
        handleClick={handleClick}
        error={error}
        clearError={clearError}
      />
    </Container>
  );
};

export default Home;